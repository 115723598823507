<template>
  <div class="registries-page">
    <div class="page-title d-flex py-2 justify-content-between">
      <h1 class="h4 mb-0">Перечень реестров платежей по договорам подряда</h1>

      <div class="d-flex align-items-center">
        <BButton class="ml-3" variant="outline-primary" v-b-modal.import
          >Импортировать
        </BButton>
        <BButton
          v-if="isContractor"
          class="ml-3"
          variant="primary"
          to="/create-registry"
          >Создать
        </BButton>
      </div>
    </div>

    <TableFilter
      :columns="registriesFields"
      @update-selected-columns="columns = $event"
      @search="filter = $event"
      @clear="clearFilterData"
      @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
    />

    <DefaultTable
      :data="registries"
      :fields="registriesFieldsFiltered"
      :filter="filter"
      :loading="loading"
      rowLink="/registries"
      emptyText="Нет реестров платежей"
      :noLocalSorting="true"
      @sort-change="sorting"
      @pagination-change="paginationChange"
      @per-page-change="perPageChange"
    />

    <FilterSidebar
      id="filter"
      v-model="filterData"
      @apply="updateDataWithFilters"
    />

    <b-modal id="import" centered title="Импорт реестров платежей">
      <div class="p-2 bg-light">
        <div class="font-weight-bold text-muted">
          Файл для импорта <span class="text-danger">*</span>
        </div>

        <b-form-file v-model="importFile" class="mt-1" plain />
      </div>

      <template #modal-footer="{ hide }">
        <b-button variant="outline-primary" @click="hide()"> Отмена </b-button>
        <b-button variant="primary" @click="sendImport">
          Импортировать
        </b-button>
      </template>
    </b-modal>

    <b-modal id="import-success" centered title="Импорт завершён">
      <div class="p-2 bg-light">
        <p>
          Импортирование файла «{{ importFile ? importFile.name : "" }}»
          завершено
        </p>
      </div>

      <template #modal-footer="{ hide }">
        <b-button variant="primary" @click="hide()"> Хорошо </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TableFilter from "@/components/TableFilter";
import DefaultTable from "@/components/Tables/Default";
import FilterSidebar from "@/components/FilterSidebar";

function initialFilterData() {
  return {
    number: "",
    provider_status: "",
    date: ["", ""],
    provider_id: "",
    provider_contract_id: "",
    provider_contract_date: ["", ""],
  };
}

export default {
  name: "Registries",
  components: {
    TableFilter,
    DefaultTable,
    FilterSidebar,
  },
  computed: {
    isContractor() {
      return this.$store.getters.isContractor;
    },
    registries() {
      return this.$store.state.registries.registries;
    },
    registriesFieldsFiltered() {
      let res = [];
      this.registriesFields.forEach((item) => {
        if (this.columns.filter((col) => col === item.label).length) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.registriesFields;
    },
  },
  data: () => ({
    filter: "",
    columns: [],
    filterData: initialFilterData(),
    loading: false,
    importFile: "",
    registriesFields: [
      {
        key: "number",
        sortable: true,
        label: "№",
        full: "Номер реестра",
        openDefault: true,
      },
      {
        key: "date",
        sortable: true,
        label: "Дата",
        full: "Дата",
        openDefault: true,
      },
      {
        key: "contractor.name",
        nesting: "contractor_contr_agent_id",
        sortable: true,
        label: "Подрядчик",
        full: "Подрядчик",
        openDefault: true,
      },
      {
        key: "provider.name",
        nesting: "provider_contr_agent_id",
        sortable: true,
        label: "Поставщик",
        full: "Поставщик",
        openDefault: true,
      },
      {
        key: "provider_contract_number",
        nesting: "provider_contract_id_number",
        sortable: true,
        label: "№ договора поставки",
        full: "№ договора поставки",
        openDefault: true,
      },
      {
        key: "provider_contract_date",
        nesting: "provider_contract_id_date",
        sortable: true,
        label: "Дата договора поставки",
        full: "Дата договора поставки",
        openDefault: true,
      },
      {
        key: "provider_status",
        sortable: true,
        label: "Статус согласования с поставщиком",
        full: "Статус согласования с поставщиком",
        openDefault: true,
      },
      {
        key: "responsible_full_name",
        sortable: true,
        label: "Ответственный исполнитель",
        full: "Ответственный исполнитель",
        openDefault: true,
      },
      {
        key: "responsible_phone",
        sortable: true,
        label: "Телефон",
        full: "Телефон ответственного исполнителя",
        openDefault: true,
      },
      {
        key: "comment",
        sortable: false,
        label: "Комм",
        full: "Комментарий",
        openDefault: true,
      },
    ],
    breadcrumbs: [
      { title: "Главная", link: "/" },
      { title: "Заказ ПО", link: "/" },
      { title: "Перечень реестров платежей по договорам подряда" },
    ],
    sortingData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
  }),
  methods: {
    async getData() {
      this.loading = true;
      await this.$store.dispatch("getRegistries");
      this.loading = false;
    },
    clearFilterData() {
      this.filterData = initialFilterData();
      this.getData();
    },
    sendImport() {
      if (this.importFile !== null) {
        this.$bvModal.show("import-success");
        this.$bvModal.hide("import");
      } else {
        this.$bvToast.toast("Вам необходимо загрузить файл формата XML", {
          title: "Вы не прикрепили файл для импорта",
          toaster: "b-toaster-top-right",
          solid: true,
          appendToast: false,
          variant: "danger",
        });
      }
    },
    async updateDataWithFilters(clearSort) {
      this.loading = true;
      if (clearSort) {
        this.sortingData.sort_field = "";
        this.sortingData.sort_order = "";
        this.sortingData.page = "";
      }
      await this.$store.dispatch("getRegistries", {
        ...this.filterData,
        ...this.sortingData,
      });
      this.loading = false;
    },
    sorting(event) {
      this.sortingData.sort_field = event.sortBy;
      this.sortingData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updateDataWithFilters();
    },
    paginationChange(page) {
      this.sortingData.page = page;
      this.updateDataWithFilters();
    },
    perPageChange(perPage) {
      this.sortingData.page = "";
      this.sortingData.per_page = perPage;
      this.updateDataWithFilters();
    },
  },
  mounted() {
    this.getData();
    this.registriesFields.forEach((item) => {
      if (item.openDefault) {
        this.columns.push(item.label);
      }
    });
  },
};
</script>

<style
    lang="sass"
    scoped
>
</style>
