import { render, staticRenderFns } from "./Registries.vue?vue&type=template&id=b74b94de&scoped=true&"
import script from "./Registries.vue?vue&type=script&lang=js&"
export * from "./Registries.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b74b94de",
  null
  
)

export default component.exports